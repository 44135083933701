<template>
  <div class="page-login" style="background: #fff">
    <!--头部-->
    <div class="header-wrap">
      <div class="header-cont-wrap">
        <img src="@images/logo.png" alt class="logo-img" @click="toIndex" />
        <input type="button" class="header-right-btn" value="返回首页" @click="toIndex" />
      </div>
    </div>
    <!--头部结束-->
    <!--内容部分-->
    <div class="login-main">
      <div class="main-wrap">
        <div class="login-main-right">
          <p class="login-main-p" style="visibility: hidden">微信扫码登录</p>
          <p class="login-main-title">
            <span :class="['left-title', { 'right-title': loginIndex == 2 }]" @click.stop="changeIndex(1)">账号登录</span>
            <span :class="['left-title', { 'right-title': loginIndex == 1 }]" @click.stop="changeIndex(2)">
              短信验证码登录
            </span>
          </p>
          <div class="error-wrap">
            <p class="login-error" v-if="errMsg && loginIndex == 1">{{ errMsg }}</p>
            <p class="login-error" v-if="errMsgs && loginIndex == 2">{{ errMsgs }}</p>
          </div>

          <!-- 账号密码登录 -->
          <div class="input-wrap" v-if="loginIndex == 1">
            <b-field class="field-style">
              <b-input placeholder="请输入账号" icon="account" v-model="userInfo.userName" value="" maxlength="32"
                icon-right="close-circle" icon-right-clickable @icon-right-click="clearIconClick"
                @input="resetSlideVerify"></b-input>
            </b-field>
            <img class="leftImg" src="./images/account-icon.png" alt />
          </div>
          <div class="input-wrap" v-if="loginIndex == 1">
            <b-field label class="field-style">
              <b-input type="password" value="iwantmytreasure" placeholder="请输入密码" v-model="userInfo.userPwd"
                password-reveal maxlength="32" @input="resetSlideVerify"></b-input>
            </b-field>
            <img class="leftImg" src="./images/pwd-icon.png" alt />
          </div>

          <!-- 手机验证码登录 -->
          <div class="input-checknum" v-if="loginIndex == 2">
            <b-field class="field-style">
              <b-input placeholder="请输入手机号" v-model="userInfo.phone" maxlength="32" icon-right="close-circle"
                icon-right-clickable @icon-right-click="clearClick" @input="resetSlideVerify"></b-input>
            </b-field>
            <img class="leftImg" src="./images/phone.png" alt />
          </div>
          <div class="error-wrap" v-if="errMsg && loginIndex == 2">
            <p class="login-error">{{ errMsg }}</p>
          </div>
          <div class="input-checknum" v-if="loginIndex == 2">
            <div class="right-msg">
              <b-field class="field-style checkCode">
                <b-input class="checkCode" placeholder="请输入短信验证码" v-model="userInfo.verifyCode" maxlength="32"
                  @input="resetSlideVerify"></b-input>
              </b-field>
              <span class="getMsg" v-if="!showTime" @click.stop="getVerifyCode()">获取验证码</span>
              <span class="getMsg showTime" v-if="showTime">{{ time }}s</span>
            </div>
            <img class="leftImg" src="./images/check-code.png" alt />
          </div>

          <div class="test-code">
            <div class="drag" ref="dragDiv">
              <div class="drag_bg"></div>
              <div class="drag_text">{{ confirmWords }}</div>
              <div ref="moveDiv" @mousedown="mousedownFn($event)" :class="{ handler_ok_bg: confirmSuccess }"
                class="handler handler_bg" style="position: absolute; top: 0px; left: 0px"></div>
            </div>
          </div>

          <div class="set-password" v-if="loginIndex == 1">
            <div class="field">
              <b-checkbox :value="true" type="is-info" v-model="remember">记住密码</b-checkbox>
            </div>
            <div class="foget-password" @click="toForget()">忘记密码？</div>
          </div>

          <div class="buttons">
            <b-button type="is-info" :disabled="isDisabled" expanded class="login-btn" @click="onLogin">登录</b-button>
          </div>
          <div @click="goRegist()" class="regist">还没有帐号？立即注册 </div>
        </div>
      </div>
    </div>

    <!--内容部分结束-->
    <PageFooter :loginPage="loginPage"></PageFooter>
  </div>
</template>
<script>
import { PageFooter } from "@components/page-footer";
import { AccountStorage } from "@services/account";
import { LoginService } from "./login.service";

const accountStorage = new AccountStorage();
const loginService = new LoginService();

let timer;
export default {
  components: {
    PageFooter
  },
  data() {
    return {
      loginPage: true,
      beginClientX: 0 /*距离屏幕左端距离*/,
      mouseMoveStata: false /*触发拖动状态  判断*/,
      maxwidth: "" /*拖动最大宽度，依据滑块宽度算出来的*/,
      confirmWords: "拖动滑块验证" /*滑块文字*/,
      confirmSuccess: false /*验证成功判断*/,
      errMsg: "",
      errMsgs: "",
      remember: false,
      userInfo: {
        userName: "",
        userPwd: "",
        phone: '',
        verifyCode: ''
      },
      loginIndex: 1,
      showTime: false,
      time: null,
      errCheck: false, // 验证码错误
    };
  },
  computed: {
    // 是否禁用登录按钮
    isDisabled() {
      if (
        this.confirmSuccess &&
        this.userInfo.userName &&
        this.userInfo.userPwd
      ) {
        return false;
      } else if (
        this.confirmSuccess &&
        this.userInfo.phone &&
        this.userInfo.verifyCode
      ) {
        return false;
      } else {
        return true;
      }
    },
    flag() {
      return this.$route.path === "/account/forget-password";
    },
  },
  created() {
    this.enterLogin();
    let userId = accountStorage.getCacheLastAccountId();
    if (userId) {
      let accountIdInfo = accountStorage.getCacheAccountId(userId);
      if (accountIdInfo) {
        if (accountIdInfo.vcode) {
          this.userInfo.phone = accountIdInfo.id;
        } else {
          this.userInfo.userName = accountIdInfo.id;
        }
        if (accountIdInfo.pwd) {
          this.userInfo.userPwd = accountIdInfo.pwd;
          this.remember = true;
        }
      }
    }
  },
  async mounted() {
    this.maxwidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
    this.bindEvent1();
  },
  //离开之前清除缓存
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem('stopTime')
    next()
  },
  methods: {
    bindEvent1() {
      document
        .getElementsByTagName("html")[0]
        .addEventListener("mousemove", this.mouseMoveFn);
      document
        .getElementsByTagName("html")[0]
        .addEventListener("mouseup", this.moseUpFn);
    },
    bindEvent2() {
      document
        .getElementsByTagName("html")[0]
        .removeEventListener("mousemove", this.mouseMoveFn);
      document
        .getElementsByTagName("html")[0]
        .removeEventListener("mouseup", this.moseUpFn);
    },
    enterLogin() {
      document.onkeydown = () => {
        if (this.$route.name === "accountLogin") {
          var key = window.event.keyCode;
          if (key === 13) this.onLogin();
        }
      };
    },
    resetSlideVerify() {
      if (this.userInfo.phone) {
        this.errMsgs = ""
      }
      this.beginClientX = 0;
      this.mouseMoveStata = false;
      this.confirmSuccess = false;
      this.confirmWords = "拖动滑块验证";
      document.getElementsByClassName("drag_text")[0].style.color = "#000";
      document.getElementsByClassName("handler")[0].style.left = "0px";
      document.getElementsByClassName("drag_bg")[0].style.width = "0px";
      this.maxwidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
      this.bindEvent2();
      this.bindEvent1();
    },
    clearIconClick() {
      this.userInfo.userName = "";
    },
    clearClick() {
      this.userInfo.phone = "";
    },
    async onLogin() {
      let params = {
        account: this.loginIndex == 1 ? this.userInfo.userName : this.userInfo.phone,
        password: this.loginIndex == 1 ? this.userInfo.userPwd : '',
        remember: this.remember,
        verifyCode: this.userInfo.verifyCode,
        loginType: this.loginIndex == 1 ? 'ACCOUNT' : 'VERIFY'
      };
      const result = await loginService.siginIn(params);
      if (result.code === "200") {
        this.errMsg = "";
        this.toIndex();
      } else {
        this.errMsg = result.msg;
        this.resetSlideVerify();
      }
    },
    toIndex() {
      this.$router.push({ path: "/" });
    },
    // 忘记密码
    toForget() {
      this.$router.push({ path: '/account/forget-password' })
    },
    mousedownFn: function (e) {
      if (
        (this.userInfo.userName.length > 0 &&
          this.userInfo.userPwd.length > 0 &&
          this.loginIndex == 1) ||
        (this.userInfo.phone.length > 0 &&
          this.userInfo.verifyCode.length > 0 &&
          this.loginIndex == 2)
      ) {
        if (!this.confirmSuccess) {
          e.preventDefault && e.preventDefault(); //阻止文字选中等 浏览器默认事件
          this.mouseMoveStata = true;
          this.beginClientX = e.clientX;
        }
      }
    }, //mousedoen 事件
    successFunction() {
      this.confirmSuccess = true;
      this.confirmWords = "验证通过";
      if (window.addEventListener) {
        this.bindEvent2();
      } else {
        document
          .getElementsByTagName("html")[0]
          .removeEventListener("mouseup", () => { });
      }
      document.getElementsByClassName("drag_text")[0].style.color = "#fff";
      document.getElementsByClassName("handler")[0].style.left = this.maxwidth + "px";
      document.getElementsByClassName("drag_bg")[0].style.width = this.maxwidth + "px";
    }, //验证成功函数
    mouseMoveFn(e) {
      if (this.mouseMoveStata) {
        let width = e.clientX - this.beginClientX;
        if (width > 0 && width <= this.maxwidth) {
          document.getElementsByClassName("handler")[0].style.left = width + "px";
          document.getElementsByClassName("drag_bg")[0].style.width = width + "px";
        } else if (width > this.maxwidth) {
          this.successFunction();
        }
      }
    }, //mousemove事件
    moseUpFn(e) {
      this.mouseMoveStata = false;
      var width = e.clientX - this.beginClientX;
      if (width < this.maxwidth) {
        if (document.getElementsByClassName("handler") && document.getElementsByClassName("handler")[0]) {
          document.getElementsByClassName("handler")[0].style.left = 0 + "px";
        }
        if (document.getElementsByClassName("drag_bg") && document.getElementsByClassName("drag_bg")[0]) {
          document.getElementsByClassName("drag_bg")[0].style.width = 0 + "px";
        }
      }
    }, //mouseup事件

    //切换登陆方式
    changeIndex(index) {
      this.loginIndex = index
      this.errMsg = ''
      this.errMsgs = ''
      this.phone = this.phone
      this.resetSlideVerify()
      if (localStorage.getItem('stopTime')) {
        if (0 < this.getCount() && this.getCount() < 90) {
          this.showTime = true
          this.getMsg()
        }
      }
    },
    // 立即注册
    goRegist() {
      this.$router.push({
        path: '/account/register'
      })
    },
    getVerifyCode() {
      if (!this.userInfo.phone) {
        this.errMsgs = "请输入手机号！"
        return
      }
      let res = /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/
      if (!res.test(this.userInfo.phone)) {
        this.errMsgs = "手机号码有误！"
        return
      } else {
        this.errMsgs = ""
      }
      let url = `/pjyy-deepexi-user-center/api/v1/common/toLogin/sendVerifyCode?phone=${this.userInfo.phone}`
      this.$getHttpClient().post(url).then(res => {
        if (res.data.code == '200') {
          localStorage.setItem('stopTime', new Date().getTime() + 90 * 1000)
          this.getMsg()
        }
      })
    },
    //获取验证码
    getMsg() {
      let that = this
      this.time = this.getCount();
      if (timer) {
        clearInterval(timer)
      }
      //设置button效果，开始计时
      timer = setInterval(() => {
        if (that.time <= 0) {
          clearInterval(timer);//停止计时器
          that.showTime = false
        } else {
          that.showTime = true
          that.time--;
        }
      }, 1000);
    },
    //通过本地缓存获取倒计时
    getCount() {
      let now = new Date().getTime();
      if (localStorage.getItem('stopTime')) {
        let stopTime = localStorage.getItem('stopTime');
        let remainTime = ((stopTime - now) / 1000).toFixed();
        //设定的倒计时，
        if (remainTime > 0) {
          //剩下多少秒
          return remainTime;
        } else {
          localStorage.setItem('stopTime', 0);
          return 0;
        }
      } else {
        localStorage.setItem('stopTime', new Date().getTime() + 90 * 1000);
        return 90;
      }
    }
  }
};
</script>
<style src="./login.less" lang="less">

</style>
